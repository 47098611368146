<template>
  <div class="router_view_box">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media (max-width: 766px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
.router_view_box {
  position: relative;
  max-width: 766px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}
</style>
